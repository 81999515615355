const gradeEnum = {
    0: 'Paud',
    1: 'Cabe Rawit 1',
    2: 'Cabe Rawit 2',
    3: 'Cabe Rawit 3',
    4: 'Cabe Rawit 4',
    5: 'Cabe Rawit 5',
    6: 'Cabe Rawit 6',
    7: 'Pra Remaja 1',
    8: 'Pra Remaja 2',
    9: 'Pra Remaja 3',
    10: 'Remaja 1',
    11: 'Remaja 2',
    12: 'Remaja 3',
    13: 'Pra Nikah 1',
    14: 'Pra Nikah 2',
    15: 'Pra Nikah 3',
    16: 'Pra Nikah 4',
}

export default gradeEnum